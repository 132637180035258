<template>
  <div class="pagePadding">
    <ul class="topNavDiv">
      <li v-for="(item, index) in navFrist" :key="index" :class="{ clickActive: clickIndex == item.type, finger: true }" @click="clickNav(item.type)">
        {{ item.name }}
        <div class="linear" v-if="clickIndex == item.type"></div>
      </li>
    </ul>
    <div class="btmDiv">
      <!--营业执照信息-->
      <div class="formDiv">
        <ProductBaseInfo v-show="clickIndex == 1" ref="productBaseInfo"></ProductBaseInfo>
        <RegisteCerticate v-show="clickIndex == 2" ref="registeCerticate"></RegisteCerticate>
        <RecordCertificateP v-show="clickIndex == 3" ref="recordCertificateP"></RecordCertificateP>
        <DealerInfo v-show="clickIndex == 4" ref="dealerInfo"></DealerInfo>
      </div>
      <div class="saveDiv clearfix">
        <div class="pageBtn finger btnSure fr" v-show="clickIndex == 4" @click="post">提交</div>
        <div v-show="clickIndex == 4" class="noticeText">(点击提交,将信息提交至管理端等待审核)</div>
        <div class="pageBtn finger btnSure fr marginRight18" @click="next(clickIndex)">保存</div>
        <span class="pageBtn finger btnReset fr marginRight18" @click="back">返回</span>
      </div>
    </div>
    <!--    提交-->
    <tips-component showModal="post" v-if="postStatus" @cancleBtn="postStatus = false" @sureBrn="surePost"></tips-component>
    <tips-component showModal="chongfu" v-if="chongfuFlag" @cancleBtn="chongfuFlag = false" @sureBrn="sureChongfu"></tips-component>
  </div>
</template>

<script>
import TipsComponent from '@/components/tipsComponent'
import { debounce } from "@/service/utils";
export default {
  name: 'addProduct',
  components: {
    ProductBaseInfo: () => import('./productBaseInfo'),
    RegisteCerticate: () => import('./registeCerticate'),
    RecordCertificateP: () => import('./recordCertificateP'),
    DealerInfo: () => import('./dealerInfo'),
    TipsComponent
  },
  data() {
    return {
      chongfuFlag:false,
      postStatus: false,
      lastPostObj: {},
      detailInfo: {}, // 有id 查询详情
      operation: '', // 1 是新增 2是编辑
      id: '',
      navFrist: [
        {
          name: '基础信息',
          type: 1
        }
        // {
        //   name: '注册证',
        //   type: 2
        // },
        // {
        //   name: '备案凭证',
        //   type: 3
        // },
        // {
        //   name: '经销信息',
        //   type: 4
        // }
      ],
      clickIndex: 1,
      reviewId: ''
    }
  },
  created() {
    this.$store.commit('setProductInfo', {})
  },
  methods: {
    // 返回
    back() {
      this.$router.go(-1)
    },
    post() {
      let postFrom1 = this.$refs.productBaseInfo.postFrom
      let postFrom2 = {}
      let postFrom4 = {}
      if (!postFrom1.name) {
        this.$Message.warning('请选择/填写产品名称')
        return
      }
      if (!postFrom1.factory_id) {
        this.$Message.warning('请选择厂家')
        return
      }
      if (!postFrom1.storage_condition) {
        this.$Message.warning('请填写储运条件')
        return
      }
      // if (!postFrom1.unit.dose_unit || !postFrom1.unit.qty || !postFrom1.unit.unit) {
      //   this.$Message.warning('请填写最小包装规格')
      //   return
      // }
      // if (!postFrom1.packing_size) {
      //   this.$Message.warning('请填写包装规格')
      //   return
      // }
      if (!postFrom1.qualification_maintain_type) {
        this.$Message.warning('请选择资料维护')
        return
      }
      if (postFrom1.image_urls.length === 0) {
        this.$Message.warning('请上传图片资料')
        return
      }
      if (postFrom1.qualification_maintain_type == '1') {
        postFrom2 = this.$refs.registeCerticate.postFrom
        let arr = this.$refs.registeCerticate.listData
        arr.forEach((item,index) =>{
        item.notax_unit_price =(item.unit_price/(1+item.tax_rate/100)).toFixed(4)
      })
        if (!postFrom2.licence_file_key || !postFrom2.licence_file_name || !postFrom2.licence_file_url) {
          this.$Message.warning('请上传注册证')
          return
        }
        if (!postFrom2.licence_code) {
          this.$Message.warning('请填写注册证编号')
          return
        }
        if (!postFrom2.production_address) {
          this.$Message.warning('请填写生产地址')
          return
        }
        if (!postFrom2.creator_company_name) {
          this.$Message.warning('请填写注册人名称')
          return
        }
        if (!postFrom2.creator_company_address) {
          this.$Message.warning('请填写注册人住所')
          return
        }
        if (!postFrom2.proxy_name) {
          this.$Message.warning('请填写代理人名称')
          return
        }
        if (!postFrom2.comprise_desc) {
          this.$Message.warning('请填写结构及组成')
          return
        }
        if (!postFrom2.application_scope) {
          this.$Message.warning('请填写适用范围')
          return
        }
        if (!postFrom2.registrant_time) {
          this.$Message.warning('请选择批准日期')
          return
        }
        if (!postFrom2.end_time) {
          this.$Message.warning('请选择有效期至')
          return
        }
        if (arr.length == 0) {
          this.$Message.warning('请新增至少一组规格型号')
          return
        }
        for (let i = 0; i < arr.length; i++) {
          if (!arr[i].model_name) {
            this.$Message.warning(`请填写第${i + 1}组规格型号再提交`)
            return
          }
          if (!arr[i].unit_price) {
            this.$Message.warning(`请填写第${i + 1}组单价数据再提交`)
            return
          }
          if (!arr[i].tax_rate && arr[i].tax_rate!=0) {
            this.$Message.warning(`请填写第${i + 1}组税率数据再提交`)
            return
          }
          if (arr[i].tax_rate * 1 > 100) {
            this.$Message.warning(`第${i + 1}税率的可输入值只能在0-100`)
            return
          }
        }
        // 包装单位必填
        for (let i = 0; i < arr.length; i++) {
          if (!arr[i].unit.unit) {
            this.$Message.warning(`请填写第${i + 1}组最小包装规格再提交`)
            return
          }
        }
        postFrom2.licence_product_code = arr
      } else {
        postFrom2 = this.$refs.recordCertificateP.postFrom
        let arr = this.$refs.recordCertificateP.listData
        arr.forEach((item,index) =>{
          item.notax_unit_price =(item.unit_price/(1+item.tax_rate/100)).toFixed(4)
        })
        if (!postFrom2.record_file_name) {
          this.$Message.warning('请上传注册证')
          return
        }
        if (!postFrom2.record_code) {
          this.$Message.warning('请填写备案号')
          return
        }
        if (!postFrom2.record_name) {
          this.$Message.warning('请填写备案号名称')
          return
        }
        if (!postFrom2.record_time) {
          this.$Message.warning('请选择备案日期')
          return
        }
        if (arr.length == 0) {
          this.$Message.warning('请至少添加一组规格型号')
          return
        }
        for (let i = 0; i < arr.length; i++) {
          if (!arr[i].model_name) {
            this.$Message.warning(`请填写第${i + 1}组规格型号再提交`)
            return
          }
          if (!arr[i].unit_price) {
            this.$Message.warning(`请填写第${i + 1}组单价数据再提交`)
            return
          }
          if (!arr[i].tax_rate && arr[i].tax_rate!=0) {
            this.$Message.warning(`请填写第${i + 1}组税率数据再提交`)
            return
          }
          if (arr[i].tax_rate * 1 > 100) {
            this.$Message.warning(`第${i + 1}税率的可输入值只能在0-100`)
            return
          }
        }
        // 包装单位必填
        for (let i = 0; i < arr.length; i++) {
          if (!arr[i].unit.unit) {
            this.$Message.warning(`请填写第${i + 1}组最小包装规格再提交`)
            return
          }
        }
        postFrom2.record_product_code = arr
      }
      postFrom4 = this.$refs.dealerInfo.postFrom
      if (!postFrom4.payment_days) {
        this.$Message.warning('请填写账期')
        return
      }
      if (!postFrom4.deal_type) {
        this.$Message.warning('请填写交易类型')
        return
      }
      let lastPostFrom = Object.assign({}, postFrom1, postFrom2, postFrom4)
      lastPostFrom.image_files = lastPostFrom.image_files ? lastPostFrom.image_files.join(',') : ''
      lastPostFrom.image_file_name = lastPostFrom.image_file_name ? lastPostFrom.image_file_name.join(',') : ''
      lastPostFrom.review_id = this.reviewId
      lastPostFrom.save_type = 2
      this.lastPostObj = lastPostFrom
      this.postStatus = true
    },
    // 确认提交
    surePost:debounce(function() {
      this.$http.post(this.$api.productSave, this.lastPostObj, true).then(res => {
        this.postStatus = false
        this.$Message.success('提交审核成功')
        this.$router.go(-1)
      })
      sessionStorage.setItem('updateList','0')
    }, 1000, true),
    // 保存备案凭证
    saveRecord() {
      let postFrom3 = this.$refs.recordCertificateP.postFrom
      let arr = this.$refs.recordCertificateP.listData
      arr.forEach((item,index) =>{
        item.notax_unit_price =(item.unit_price/(1+item.tax_rate/100)).toFixed(4)
      })
      postFrom3.review_id = this.reviewId
      if (!postFrom3.record_file_name) {
        this.$Message.warning('请上传备案凭证照片')
        return
      }
      if (!postFrom3.record_code) {
        this.$Message.warning('请填写备案号')
        return
      }
      if (!postFrom3.record_name) {
        this.$Message.warning('请填写备案人名称')
        return
      }
      if (!postFrom3.record_time) {
        this.$Message.warning('请选择备案日期')
        return
      }
      if (arr.length == 0) {
        this.$Message.warning('请至少添加一组规格型号')
        return
      }
      for (let i = 0; i < arr.length; i++) {
          if (!arr[i].model_name) {
            this.$Message.warning(`请填写第${i + 1}组规格型号再提交`)
            return
          }
          if (!arr[i].unit_price) {
            this.$Message.warning(`请填写第${i + 1}组单价数据再提交`)
            return
          }
          if (!arr[i].tax_rate && arr[i].tax_rate!=0) {
            this.$Message.warning(`请填写第${i + 1}组税率数据再提交`)
            return
          }
        // if (!arr[i].unit.dose_unit || !arr[i].unit.qty || !arr[i].unit.unit) {
        //   this.$Message.warning('请填写最小包装规格')
        //   return
        // }
      }
      // 包装单位必填
      for (let i = 0; i < arr.length; i++) {
        if (!arr[i].unit.unit) {
          this.$Message.warning(`请填写第${i + 1}组最小包装规格再提交`)
          return
        }
      }
      for (let i = 0; i < arr.length; i++) {
         this.$set(arr[i],'is_approved',0)
      }
      postFrom3.record_product_code = arr
      this.$http.post(this.$api.productProductRecord, postFrom3, true).then(res => {
        this.$Message.success('保存成功')
        this.clickIndex = 4
      })
    },
    // 保存产品经销协议信息
    saveAgreement() {
      let postFrom4 = this.$refs.dealerInfo.postFrom
      postFrom4.review_id = this.reviewId || 23
      if (!postFrom4.payment_days) {
        this.$Message.warning('请填写账期')
        return
      }
      if (!postFrom4.deal_type) {
        this.$Message.warning('请填写交易类型')
        return
      }
      this.$http.post(this.$api.productProductAgreement, postFrom4, true).then(res => {
        this.$Message.success('保存成功')
        this.$router.go(-1)
      })
    },
    // 保存基础信息
    saveBasc(stringObj) {
      let postFrom = JSON.parse(stringObj)
      console.log(stringObj,'数字系信息')
      if (!postFrom.name) {
        this.$Message.warning('请选择/填写产品名称')
        return
      }
      if (!postFrom.factory_id) {
        this.$Message.warning('请选择厂家')
        return
      }
      // if (!postFrom.packing_size) {
      //   this.$Message.warning('请填写装箱规格')
      //   return
      // }
      // if (!postFrom.unit) {
      //   this.$Message.warning('请填写计量单位')
      //   return
      // }
      // postFrom.unit = {
      //   qty: postFrom.unit.qty ? postFrom.unit.qty : '',
      //   unit: postFrom.unit.unit ? postFrom.unit.unit : '',
      //   dose_unit: postFrom.unit.dose_unit ? postFrom.unit.dose_unit : ''
      // }
      // postFrom.packing_size = {
      //   qty: postFrom.packing_size.qty ? postFrom.packing_size.qty : '',
      //   unit: postFrom.unit.unit ? postFrom.unit.unit : '',
      //   dose_unit: postFrom.packing_size.dose_unit ? postFrom.packing_size.dose_unit : ''
      // }

      if(postFrom.qualification_maintain_type == 1) {
              // 个生产凭证和备案凭证赋值
        // this.$refs.registeCerticate.listData = stringObj
      } else if(postFrom.qualification_maintain_type == 2) {
        // this.$refs.recordCertificateP.listData = stringObj
      }
      if (!postFrom.storage_condition) {
        this.$Message.warning('请填写储运条件')
        return
      }
      if (!postFrom.qualification_maintain_type) {
        this.$Message.warning('请选择资料维护')
        return
      }
      console.log(postFrom.unit)
      // if (!postFrom.unit.dose_unit || !postFrom.unit.qty || !postFrom.unit.unit) {
      //   this.$Message.warning('请填写最小包装规格')
      //   return
      // }
      if (postFrom.image_urls.length === 0) {
        this.$Message.warning('请上传图片资料')
        return
      }
      postFrom.image_files = postFrom.image_files ? postFrom.image_files.join(',') : ''
      postFrom.image_file_name = postFrom.image_file_name ? postFrom.image_file_name.join(',') : ''
      this.$http.post(this.$api.productProductBasc, postFrom, true).then(res => {
        this.reviewId = res.data.review_id
        this.$Message.success('保存成功')
        let obj = {}
        this.navFrist = [
          {
            name: '基础信息',
            type: 1
          }
        ]
        if (postFrom.qualification_maintain_type == 1) {
          obj = {
            name: '注册证',
            type: 2
          }
        } else if (postFrom.qualification_maintain_type == 2) {
          obj = {
            name: '备案凭证',
            type: 3
          }
        }
        this.navFrist = [
          ...this.navFrist,
          obj,
          {
            name: '经销信息',
            type: 4
          }
        ]
        if (postFrom.qualification_maintain_type == 1) {
          this.clickIndex = 2
        } else {
          this.clickIndex = 3
        }
      })
    },
    // 保存注册证
    saveRegiste() {
      let postFrom2 = this.$refs.registeCerticate.postFrom
      let arr = this.$refs.registeCerticate.listData
      arr.forEach((item,index) =>{
        item.notax_unit_price =(item.unit_price/(1+item.tax_rate/100)).toFixed(4)
      })
      if (!postFrom2.licence_file_key || !postFrom2.licence_file_name || !postFrom2.licence_file_url) {
        this.$Message.warning('请上传注册证')
        return
      }
      if (!postFrom2.licence_code) {
        this.$Message.warning('请输入注册证编号')
        return
      }
      if (!postFrom2.production_address) {
        this.$Message.warning('请输入生产地址')
        return
      }
      if (!postFrom2.creator_company_name) {
        this.$Message.warning('请输入注册人名称')
        return
      }
      if (!postFrom2.creator_company_address) {
        this.$Message.warning('请输入注册人住所')
        return
      }
      if (!postFrom2.proxy_name) {
        this.$Message.warning('请输入代理人名称')
        return
      }
      if (!postFrom2.comprise_desc) {
        this.$Message.warning('请输入结构及组成')
        return
      }
      if (!postFrom2.application_scope) {
        this.$Message.warning('请输入适用范围')
        return
      }
      if (!postFrom2.registrant_time) {
        this.$Message.warning('请选择批准日期')
        return
      }
      if (!postFrom2.end_time) {
        this.$Message.warning('请选择有效期至')
        return
      }
      if (arr.length == 0) {
        this.$Message.warning('请新增至少一组规格型号')
        return
      }
      for (let i = 0; i < arr.length; i++) {
          if (!arr[i].model_name) {
            this.$Message.warning(`请填写第${i + 1}组规格型号再提交`)
            return
          }
          if (!arr[i].unit_price) {
            this.$Message.warning(`请填写第${i + 1}组单价数据再提交`)
            return
          }
          if (!arr[i].tax_rate && arr[i].tax_rate!=0) {
            this.$Message.warning(`请填写第${i + 1}组税率数据再提交`)
            return
          }
      }
      // 包装单位必填
      for (let i = 0; i < arr.length; i++) {
        if (!arr[i].unit.unit) {
          this.$Message.warning(`请填写第${i + 1}组最小包装规格再提交`)
          return
        }
      }
      for (let i = 0; i < arr.length; i++) {
         this.$set(arr[i],'is_approved',0)
      }
      postFrom2.licence_product_code = arr
      postFrom2.review_id = this.reviewId
      this.$http.post(this.$api.productProductLicense, postFrom2, true).then(res => {
        if(res.status){
          if(res.message == '产品已添加，请勿重复添加'){
            this.chongfuFlag = true
          }else {
             this.$Message.success('保存成功')
              this.clickIndex = 4
          }
        }
      })
    },
    sureChongfu(){
       this.$router.push({
        path: '/productManage'
      })
    },
    next(index) {
      if (index == 1) {
        let form = JSON.stringify(this.$refs.productBaseInfo.postFrom)
        this.saveBasc(form)
      } else if (index == 2) {
        this.saveRegiste()
      } else if (index == 3) {
        this.saveRecord()
      } else if (index == 4) {
        this.saveAgreement()
      }
       sessionStorage.setItem('updateList','0')
    },
    clickNav(type) {
      this.clickIndex = type
    },
    noProduct() {
      this.navFrist = [
        {
          name: '基础信息',
          type: 1
        }
      ]
    }
  }
}
</script>

<style scoped lang="less">
.marginRight18 {
  margin-right: 18px;
}
.pagePadding {
  padding: 0 !important;
  background: #f0f0f0 !important;
  height: 100px;
  display: flex;
  flex-direction: column;
  .btmDiv {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 0px 25px 30px 25px;
    .formDiv {
      flex: 1;
      overflow-y: auto;
      margin-bottom: 30px;
    }
    .saveDiv {
      height: 36px;
      position: relative;
    }
  }
  .topNavDiv {
    height: 42px;
    li {
      float: left;
      width: 120px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
      position: relative;
    }
    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}
.noticeText{
  position: absolute;
  bottom: -20px;
  color: red;
  right: -20px;
  font-size: 14px;
}
</style>
